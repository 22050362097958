import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
export default {
  data: function data() {
    return {
      activeName: 'feature',
      featureCanvasWidth: 480,
      featureCanvasHeight: 0,
      response: null,
      masterImg: null,
      masterFile: null,
      sampleImg: null,
      sampleFile: null,
      matchResponse: null,
      matchCanvasWidth: 740,
      matchCanvasHeight: 0,
      matchImageWidth: 360,
      scaleWidth: 320,
      dotRadius: 3,
      baseApi: process.env.VUE_APP_BASE_API
    };
  },
  methods: {
    handleOnSuccess: function handleOnSuccess(res, file) {
      var _this = this;

      var code = res.code,
          data = res.data;

      if (code === 0) {
        this.response = data.sample;
        var imageObj = new Image();

        imageObj.onload = function () {
          _this.featureCanvasHeight = _this.featureCanvasWidth * imageObj.height / imageObj.width;
          setTimeout(function () {
            _this.drawFeaturePoints(imageObj);

            URL.revokeObjectURL(imageObj.src);
          }, 100);
        };

        imageObj.src = URL.createObjectURL(file.raw);
      } else {
        this.$message.error('Something wrong.. please retry later.');
      }
    },
    drawFeaturePoints: function drawFeaturePoints(imageObj) {
      var canvas = document.getElementById('featureCanvas');
      var context = canvas.getContext('2d');
      context.drawImage(imageObj, 0, 0, canvas.width, canvas.height);
      var scale = canvas.width / this.scaleWidth;

      for (var i = 0; i < this.response.points.length; i += 1) {
        var tempMarker = this.response.points[i];
        context.strokeStyle = this.getRandomColor();
        context.lineWidth = 1;
        context.beginPath();
        context.arc(tempMarker[0] * scale, tempMarker[1] * scale, this.dotRadius, 0, 2 * Math.PI, true);
        context.stroke();
      }
    },
    handleOnMasterChange: function handleOnMasterChange(file) {
      this.masterFile = file.raw;
      this.masterImg = URL.createObjectURL(file.raw);
      this.matchImages();
    },
    handleOnSampleChange: function handleOnSampleChange(file) {
      this.sampleFile = file.raw;
      this.sampleImg = URL.createObjectURL(file.raw);
      this.matchImages();
    },
    resetMatches: function resetMatches() {
      this.masterImg = null;
      this.masterFile = null;
      this.sampleImg = null;
      this.sampleFile = null;
      this.matchResponse = null;
    },
    matchImages: function matchImages() {
      var _this2 = this;

      if (this.masterFile != null && this.sampleFile != null) {
        var formData = new FormData();
        formData.append('sample', this.sampleFile);
        formData.append('master', this.masterFile);
        formData.append('scale_width', this.scaleWidth);
        axios.post("".concat(this.baseApi, "/feature/api/show/match"), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (res) {
          if (res.status === 200 && res.data.code === 0) {
            _this2.matchResponse = res.data.data;
            var masterImageObj = new Image();

            masterImageObj.onload = function () {
              var masterHeight = _this2.matchImageWidth * masterImageObj.height / masterImageObj.width;
              var sampleImageObj = new Image();

              sampleImageObj.onload = function () {
                var sampleHeight = _this2.matchImageWidth * sampleImageObj.height / sampleImageObj.width;
                _this2.matchCanvasHeight = Math.max(sampleHeight, masterHeight);
                setTimeout(function () {
                  _this2.drawMatchPoints(masterImageObj, masterHeight, sampleImageObj, sampleHeight);

                  URL.revokeObjectURL(masterImageObj.src);
                  URL.revokeObjectURL(sampleImageObj.src);
                }, 100);
              };

              sampleImageObj.src = URL.createObjectURL(_this2.sampleFile);
            };

            masterImageObj.src = URL.createObjectURL(_this2.masterFile);
          } else {
            _this2.$message.error('Something wrong.. please retry later.');

            _this2.resetMatches();
          }
        }).catch(function () {
          _this2.$message.error('Something wrong.. please retry later.');

          _this2.resetMatches();
        });
      }
    },
    getRandomColor: function getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';

      for (var i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },
    drawMatchPoints: function drawMatchPoints(master, masterHeight, sample, sampleHeight) {
      var canvas = document.getElementById('matchCanvas');
      var context = canvas.getContext('2d');
      var xOffset = this.matchImageWidth + 20;
      context.drawImage(sample, 0, 0, this.matchImageWidth, sampleHeight);
      context.drawImage(master, xOffset, 0, this.matchImageWidth, masterHeight);
      var scale = this.matchImageWidth / this.scaleWidth;
      var points = this.matchResponse.match.points;

      for (var i = 0; i < points.length; i += 1) {
        var sampleMarker = points[i][0];
        var masterMarker = points[i][1];
        context.strokeStyle = this.getRandomColor();
        context.lineWidth = 0.5;
        context.beginPath();
        context.arc(sampleMarker[0] * scale, sampleMarker[1] * scale, this.dotRadius, 0, 2 * Math.PI, true);
        context.stroke();
        context.beginPath();
        context.arc(masterMarker[0] * scale + xOffset, masterMarker[1] * scale, this.dotRadius, 0, 2 * Math.PI, true);
        context.stroke();
        context.beginPath();
        context.moveTo(masterMarker[0] * scale + xOffset, masterMarker[1] * scale);
        context.lineTo(sampleMarker[0] * scale, sampleMarker[1] * scale);
        context.stroke();
      }
    }
  }
};