import "/root/workspace/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.iterator.js";
import "/root/workspace/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js";
import "/root/workspace/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js";
import "/root/workspace/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
Vue.use(ElementUI);
new Vue({
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');