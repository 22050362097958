var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("h2", [_vm._v("iScope Recognition")]),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Where are features?", name: "feature" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "center"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "feature-container" },
                    [
                      _vm.response != null
                        ? _c(
                            "div",
                            { staticClass: "result" },
                            [
                              _c("p", [
                                _vm._v(
                                  " Feature Count: " +
                                    _vm._s(_vm.response.count) +
                                    " "
                                )
                              ]),
                              _c("canvas", {
                                attrs: {
                                  id: "featureCanvas",
                                  width: _vm.featureCanvasWidth,
                                  height: _vm.featureCanvasHeight
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-top": "20px" },
                                  attrs: { type: "primary", plain: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.response = null
                                    }
                                  }
                                },
                                [_vm._v(" Start Over ")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: "image/png, image/jpeg",
                                action: _vm.baseApi + "/feature/api/show/find",
                                name: "sample",
                                data: {
                                  scale_width: _vm.scaleWidth
                                },
                                "show-file-list": false,
                                drag: "",
                                "on-success": _vm.handleOnSuccess
                              }
                            },
                            [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _c("div", { staticClass: "el-upload__text" }, [
                                _vm._v(" Drag image here, or "),
                                _c("em", [_vm._v("click to upload")])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip"
                                },
                                [_vm._v(" Accepts only jpg/png files ")]
                              )
                            ]
                          )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Feature matching", name: "match" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center"
                  }
                },
                [
                  _c("div", { staticClass: "label-container" }, [
                    _c("div", { staticClass: "label" }, [_vm._v(" SAMPLE ")]),
                    _c("div", { staticClass: "label" }, [_vm._v(" MASTER ")])
                  ]),
                  _vm.matchResponse != null
                    ? _c(
                        "div",
                        { staticClass: "match-upload-result" },
                        [
                          _c("canvas", {
                            attrs: {
                              id: "matchCanvas",
                              width: _vm.matchCanvasWidth,
                              height: _vm.matchCanvasHeight
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                width: "100%",
                                "text-align": "left"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  " Sample features: " +
                                    _vm._s(_vm.matchResponse.sample.count) +
                                    ", Master features: " +
                                    _vm._s(_vm.matchResponse.master.count) +
                                    " "
                                )
                              ]),
                              _c("p", [
                                _vm._v(
                                  " Match features: " +
                                    _vm._s(_vm.matchResponse.match.count) +
                                    ", Match score: " +
                                    _vm._s(_vm.matchResponse.match.score) +
                                    " "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "20px" },
                              attrs: { type: "primary", plain: "" },
                              on: {
                                click: function($event) {
                                  return _vm.resetMatches()
                                }
                              }
                            },
                            [_vm._v(" Start Over ")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "match-upload" },
                        [
                          _vm.sampleImg != null
                            ? _c(
                                "div",
                                { staticClass: "match-img" },
                                [
                                  _c("img", { attrs: { src: _vm.sampleImg } }),
                                  _vm.masterImg == null
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.sampleImg = null
                                              _vm.sampleFile = null
                                            }
                                          }
                                        },
                                        [_vm._v(" Clear ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "el-upload",
                                {
                                  attrs: {
                                    accept: "image/png, image/jpeg",
                                    action: "",
                                    "auto-upload": false,
                                    "show-file-list": false,
                                    drag: "",
                                    "on-change": _vm.handleOnSampleChange
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v(" Drag "),
                                      _c("strong", [_vm._v("SAMPLE")]),
                                      _vm._v(" image here, or "),
                                      _c("em", [_vm._v("click to upload")])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip"
                                    },
                                    [_vm._v(" Accepts only jpg/png files ")]
                                  )
                                ]
                              ),
                          _vm.masterImg != null
                            ? _c(
                                "div",
                                { staticClass: "match-img" },
                                [
                                  _c("img", { attrs: { src: _vm.masterImg } }),
                                  _vm.sampleImg == null
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.masterImg = null
                                              _vm.masterFile = null
                                            }
                                          }
                                        },
                                        [_vm._v(" Clear ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "el-upload",
                                {
                                  attrs: {
                                    accept: "image/png, image/jpeg",
                                    action: "",
                                    "auto-upload": false,
                                    "show-file-list": false,
                                    drag: "",
                                    "on-change": _vm.handleOnMasterChange
                                  }
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [
                                      _vm._v(" Drag "),
                                      _c("strong", [_vm._v("MASTER")]),
                                      _vm._v(" image here, or "),
                                      _c("em", [_vm._v("click to upload")])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip"
                                    },
                                    [_vm._v(" Accepts only jpg/png files ")]
                                  )
                                ]
                              )
                        ],
                        1
                      )
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }